// For clearing floats like a boss h5bp.com/q
.clearfix {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
		content: '';
		// Fixes Opera/contenteditable bug:
		// http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
		line-height: 0;
	}
	&:after {
		clear: both;
	}
}

// Placeholder text
.placeholder(@color: @placeholderText, @textAlign: left, @opacity: 1) {
	&:-moz-placeholder {
		color: @color;
		text-align: @textAlign;
		opacity: @opacity;
	}
	&:-ms-input-placeholder {
		color: @color;
		text-align: @textAlign;
		opacity: @opacity;
	}
	&::-webkit-input-placeholder {
		color: @color;
		text-align: @textAlign;
		opacity: @opacity;
	}
}
// Multiple transformations
/*.transitionMulti (@value1,@value2:X,...) {
	@value: ~`'@{arguments}'.replace(/[\[\]]|\,\sX/g, '')`;
	-webkit-transition: @value;
	-moz-transition: @value;
	-ms-transition: @value;
	-o-transition: @value;
	transition: @value;
}*/
// Animation
.animation(@keyframe) {
	//-webkit-animation: <name> <duration> <timing_function> <delay> <iteration_count> <direction>
	-webkit-animation: @keyframe;
	-moz-animation: @keyframe;
	-o-animation: @keyframe;
	animation: @keyframe;
}

.keyframes(@name,@arguments) {
	@-moz-keyframes @name {
		@arguments();
	}
	@-webkit-keyframes @name {
		@arguments();
	}
	@-o-keyframes @name {
		@arguments();
	}
	@keyframes @name {
		@arguments();
	}
}

// Text overflow
// -------------------------
// Requires inline-block or block for proper styling
.text-overflow() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// CSS image replacement
// -------------------------
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
.hide-text {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

// Sizing shortcuts
// -------------------------
.size(@width, @height) {
	width: @width;
	height: @height;
}

.square(@size) {
	.size(@size, @size);
}

// CSS3 PROPERTIES
// --------------------------------------------------

// Blur
.blur(@radius: 0) {
	-webkit-filter: blur(@radius);
	-moz-filter: blur(@radius);
	-ms-filter: blur(@radius);
	-o-filter: blur(@radius);
	/* FF doesn't support blur filter, but SVG */
	filter: url("data:image/svg+xml;utf8,<svg height='0' xmlns='http://www.w3.org/2000/svg'><filter id='svgBlur' x='-5%' y='-5%' width='110%' height='110%'><feGaussianBlur in='SourceGraphic' stdDeviation='5'/></filter></svg>#svgBlur");
	//filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius = '@radius');
	filter: blur(@radius);
}

// Border Radius
.radius(@radius: 0) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

// Single Corner Border Radius
.border-top-left-radius(@radius) {
	-webkit-border-top-left-radius: @radius;
	-moz-border-radius-topleft: @radius;
	border-top-left-radius: @radius;
}
.border-top-right-radius(@radius) {
	-webkit-border-top-right-radius: @radius;
	-moz-border-radius-topright: @radius;
	border-top-right-radius: @radius;
}
.border-bottom-right-radius(@radius) {
	-webkit-border-bottom-right-radius: @radius;
	-moz-border-radius-bottomright: @radius;
	border-bottom-right-radius: @radius;
}
.border-bottom-left-radius(@radius) {
	-webkit-border-bottom-left-radius: @radius;
	-moz-border-radius-bottomleft: @radius;
	border-bottom-left-radius: @radius;
}

// Single Side Border Radius
.border-top-radius(@radius) {
	.border-top-right-radius(@radius);
	.border-top-left-radius(@radius);
}
.border-right-radius(@radius) {
	.border-top-right-radius(@radius);
	.border-bottom-right-radius(@radius);
}
.border-bottom-radius(@radius) {
	.border-bottom-right-radius(@radius);
	.border-bottom-left-radius(@radius);
}
.border-left-radius(@radius) {
	.border-top-left-radius(@radius);
	.border-bottom-left-radius(@radius);
}

// Drop shadows
.box-shadow(@shadow) {
	-webkit-box-shadow: @shadow;
	-moz-box-shadow: @shadow;
	box-shadow: @shadow;
}

.rotateY(@degrees) {
	-webkit-transform: rotateY(@degrees);
	-moz-transform: rotateY(@degrees);
	-ms-transform: rotateY(@degrees);
	-o-transform: rotateY(@degrees);
	transform: rotateY(@degrees);
}
.rotateZ(@degrees) {
	-webkit-transform: rotateZ(@degrees);
	-moz-transform: rotateZ(@degrees);
	-ms-transform: rotateZ(@degrees);
	-o-transform: rotateZ(@degrees);
	transform: rotateZ(@degrees);
}
.rotateYscale(@degrees,@ratio) {
	-webkit-transform: rotateY(@degrees) scale(@ratio);
	-moz-transform: rotateY(@degrees) scale(@ratio);
	-ms-transform: rotateY(@degrees) scale(@ratio);
	-o-transform: rotateY(@degrees) scale(@ratio);
	transform: rotateY(@degrees) scale(@ratio);
}

.rotateX(@degrees) {
	-webkit-transform: rotateX(@degrees);
	-moz-transform: rotateX(@degrees);
	-ms-transform: rotateX(@degrees);
	-o-transform: rotateX(@degrees);
	transform: rotateX(@degrees);
}

.rotateTranslate(@degleft,@degright,@trans) {
	-webkit-transform: rotate(@degleft) translate(@trans) rotate(@degright);
	-moz-transform: rotate(@degleft) translate(@trans) rotate(@degright);
	-ms-transform: rotate(@degleft) translate(@trans) rotate(@degright);
	-o-transform: rotate(@degleft) translate(@trans) rotate(@degright);
	transform: rotate(@degleft) translate(@trans) rotate(@degright);
}

.scale(@ratio) {
	-webkit-transform: scale(@ratio);
	-moz-transform: scale(@ratio);
	-ms-transform: scale(@ratio);
	-o-transform: scale(@ratio);
	transform: scale(@ratio);
}

.rotateYscale(@degrees,@ratio) {
	-webkit-transform: rotateY(@degrees) scale(@ratio);
	-moz-transform: rotateY(@degrees) scale(@ratio);
	-ms-transform: rotateY(@degrees) scale(@ratio);
	-o-transform: rotateY(@degrees) scale(@ratio);
	transform: rotateY(@degrees) scale(@ratio);
}

.rotateScale(@degrees,@ratio) {
	-webkit-transform: rotate(@degrees) scale(@ratio);
	-moz-transform: rotate(@degrees) scale(@ratio);
	-ms-transform: rotate(@degrees) scale(@ratio);
	-o-transform: rotate(@degrees) scale(@ratio);
	transform: rotate(@degrees) scale(@ratio);
}

// Transitions
/*
.transition(@transition) {
  -webkit-transition: @transition;
     -moz-transition: @transition;
       -o-transition: @transition;
          transition: @transition;
}
*/
.transition (@range: all, @time: 0.2s, @ease: ease-in-out) {
	-webkit-transition: @range @time @ease;
	-moz-transition: @range @time @ease;
	-o-transition: @range @time @ease;
	transition: @range @time @ease;
}
.transitionMulti (@value1,@value2:X,...) {
	@value: ~`'@{arguments}'.replace(/[\[\]]|\,\sX/g, '')`;
	-webkit-transition: @value;
	-moz-transition: @value;
	-ms-transition: @value;
	-o-transition: @value;
	transition: @value;
}
.transitionThree
	(@array_01: all 200ms ease-in-out,@array_02: all 200ms ease-in-out,@array_03: all 200ms ease-in-out) {
	-webkit-transition: @array_01, @array_02, @array_03;
	-moz-transition: @array_01, @array_02, @array_03;
	-o-transition: @array_01, @array_02, @array_03;
	transition: @array_01, @array_02, @array_03;
}
.transition-delay(@transition-delay) {
	-webkit-transition-delay: @transition-delay;
	-moz-transition-delay: @transition-delay;
	-o-transition-delay: @transition-delay;
	transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
	-webkit-transition-duration: @transition-duration;
	-moz-transition-duration: @transition-duration;
	-o-transition-duration: @transition-duration;
	transition-duration: @transition-duration;
}

// Transformations
.rotate(@degrees) {
	-webkit-transform: rotate(@degrees);
	-moz-transform: rotate(@degrees);
	-ms-transform: rotate(@degrees);
	-o-transform: rotate(@degrees);
	transform: rotate(@degrees);
}

.translate(@x, @y) {
	-webkit-transform: translate(@x, @y);
	-moz-transform: translate(@x, @y);
	-ms-transform: translate(@x, @y);
	-o-transform: translate(@x, @y);
	transform: translate(@x, @y);
}
.skew(@x, @y) {
	-webkit-transform: skew(@x, @y);
	-moz-transform: skew(@x, @y);
	-ms-transform: skewX(@x) skewY(@y); // See https://github.com/twitter/bootstrap/issues/4885
	-o-transform: skew(@x, @y);
	transform: skew(@x, @y);
	-webkit-backface-visibility: hidden; // See https://github.com/twitter/bootstrap/issues/5319
}
.translate3d(@x, @y, @z) {
	-webkit-transform: translate3d(@x, @y, @z);
	-moz-transform: translate3d(@x, @y, @z);
	-o-transform: translate3d(@x, @y, @z);
	transform: translate3d(@x, @y, @z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
.backface-visibility(@visibility) {
	-webkit-backface-visibility: @visibility;
	-moz-backface-visibility: @visibility;
	backface-visibility: @visibility;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
	-webkit-background-clip: @clip;
	-moz-background-clip: @clip;
	background-clip: @clip;
}

// Background sizing
.background-size(@size) {
	-webkit-background-size: @size;
	-moz-background-size: @size;
	-o-background-size: @size;
	background-size: @size;
}

// Box sizing
.box-sizing(@boxmodel: border-box) {
	-webkit-box-sizing: @boxmodel;
	-moz-box-sizing: @boxmodel;
	box-sizing: @boxmodel;
}

// User select
// For selecting text on the page
.user-select(@select) {
	-webkit-user-select: @select;
	-moz-user-select: @select;
	-ms-user-select: @select;
	-o-user-select: @select;
	user-select: @select;
}

// Resize anything
.resizable(@direction) {
	resize: @direction; // Options: horizontal, vertical, both
	overflow: auto; // Safari fix
}

// CSS3 Content Columns
.content-columns(@columnCount, @columnGap: @gridGutterWidth) {
	-webkit-column-count: @columnCount;
	-moz-column-count: @columnCount;
	column-count: @columnCount;
	-webkit-column-gap: @columnGap;
	-moz-column-gap: @columnGap;
	column-gap: @columnGap;
}

// Optional hyphenation
.hyphens(@mode: auto) {
	word-wrap: break-word;
	-webkit-hyphens: @mode;
	-moz-hyphens: @mode;
	-ms-hyphens: @mode;
	-o-hyphens: @mode;
	hyphens: @mode;
}

// Opacity
.opacity(@opacity) {
	opacity: @opacity / 100;
	filter: ~'alpha(opacity=@{opacity})';
}

// BACKGROUNDS
// --------------------------------------------------

// Add an alphatransparency value to any background or border color (via Elyse Holladay)
#translucent {
	.background(@color: @white, @alpha: 1) {
		background-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
	}
	.border(@color: @white, @alpha: 1) {
		border-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
		.background-clip(padding-box);
	}
}

// Gradient Bar Colors for buttons and alerts
.gradientBar(@primaryColor: #fff, @secondaryColor: #f9f9f9, @textColor: #fff, @textShadow: 0 -1px 0 rgba(0,0,0,0.25)) {
	color: @textColor;
	text-shadow: @textShadow;
	#gradient > .vertical(@primaryColor, @secondaryColor);
	border-color: @secondaryColor @secondaryColor darken(@secondaryColor, 15%);
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) fadein(rgba(0, 0, 0, 0.1), 15%);
}

// Gradients
#gradient {
	.horizontal(@startColor: #555, @endColor: #333) {
		background-color: @endColor;
		background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
		background-image: -webkit-gradient(
			linear,
			0 0,
			100% 0,
			from(@startColor),
			to(@endColor)
		); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(
			left,
			@startColor,
			@endColor
		); // Safari 5.1+, Chrome 10+
		background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
		background-image: linear-gradient(to right, @startColor, @endColor); // Standard, IE10
		background-repeat: repeat-x;
		filter: e(
			%(
				"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",
				argb(@startColor),
				argb(@endColor)
			)
		); // IE9 and down
	}

	.vertical(@startColor: #555, @endColor: #333) {
		background-color: mix(@startColor, @endColor, 60%);
		background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
		background-image: -webkit-gradient(
			linear,
			0 0,
			0 100%,
			from(@startColor),
			to(@endColor)
		); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(
			top,
			@startColor,
			@endColor
		); // Safari 5.1+, Chrome 10+
		background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
		background-image: linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
		background-repeat: repeat-x;
		filter: e(
			%(
				"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
				argb(@startColor),
				argb(@endColor)
			)
		); // IE9 and down
	}

	.directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
		background-color: @endColor;
		background-repeat: repeat-x;
		background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
		background-image: -webkit-linear-gradient(
			@deg,
			@startColor,
			@endColor
		); // Safari 5.1+, Chrome 10+
		background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
		background-image: linear-gradient(@deg, @startColor, @endColor); // Standard, IE10
	}

	.horizontal-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
		background-color: mix(@midColor, @endColor, 80%);
		background-image: -webkit-gradient(
			left,
			linear,
			0 0,
			0 100%,
			from(@startColor),
			color-stop(@colorStop, @midColor),
			to(@endColor)
		);
		background-image: -webkit-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
		background-image: -moz-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
		background-image: -o-linear-gradient(left, @startColor, @midColor @colorStop, @endColor);
		background-image: linear-gradient(to right, @startColor, @midColor @colorStop, @endColor);
		background-repeat: no-repeat;
		filter: e(
			%(
				"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
				argb(@startColor),
				argb(@endColor)
			)
		); // IE9 and down, gets no color-stop at all for proper fallback
	}

	.vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
		background-color: mix(@midColor, @endColor, 80%);
		background-image: -webkit-gradient(
			linear,
			0 0,
			0 100%,
			from(@startColor),
			color-stop(@colorStop, @midColor),
			to(@endColor)
		);
		background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
		background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
		background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
		background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
		background-repeat: no-repeat;
		filter: e(
			%(
				"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
				argb(@startColor),
				argb(@endColor)
			)
		); // IE9 and down, gets no color-stop at all for proper fallback
	}

	.radial(@innerColor: #555, @outerColor: #333) {
		background-color: @outerColor;
		background-image: -webkit-gradient(
			radial,
			center center,
			0,
			center center,
			460,
			from(@innerColor),
			to(@outerColor)
		);
		background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
		background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
		background-image: -o-radial-gradient(circle, @innerColor, @outerColor);
		background-repeat: no-repeat;
	}

	.striped(@color: #555, @angle: 45deg) {
		background-color: @color;
		background-image: -webkit-gradient(
			linear,
			0 100%,
			100% 0,
			color-stop(0.25, rgba(255, 255, 255, 0.15)),
			color-stop(0.25, transparent),
			color-stop(0.5, transparent),
			color-stop(0.5, rgba(255, 255, 255, 0.15)),
			color-stop(0.75, rgba(255, 255, 255, 0.15)),
			color-stop(0.75, transparent),
			to(transparent)
		);
		background-image: -webkit-linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
		background-image: -moz-linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
		background-image: -o-linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
		background-image: linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
	}
}

// Reset filters for IE
.reset-filter() {
	filter: e(%('progid:DXImageTransform.Microsoft.gradient(enabled = false)'));
}

.font-size(@font-size: @baseFontSize) {
	@rem: (@font-size / @baseFontSize);
	font-size: @font-size * 1px;
	font-size: ~'@{rem}rem';
}

.line-height(@line-height: @baseLineHeight) {
	@rem: (@line-height / @baseLineHeight);
	line-height: @line-height * 1px;
	line-height: ~'@{rem}rem';
}

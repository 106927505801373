@import (reference) '~vars.less';

.image {
	width: 312px;
	height: 280px;
	margin-bottom: 48px;
	background: no-repeat left center / contain;

	@media (max-width: 1109px) {
		width: 273px;
		height: 245px;
	}

	@media (max-width: 1031px) {
		margin-bottom: 32px;
	}

	@media (max-width: 783px) {
		margin-bottom: 24px;
		width: 196px;
		height: 176px;
	}
}

.title {
	margin-bottom: 16px;
}

.features {
	display: flex;
	flex-direction: column;

	.featuresTitle {
		margin-bottom: 32px;
	}

	.wrapper {
		margin-bottom: 40px;
	}

	.price {
		display: inline-block;
	}
}

.feature {
	display: flex;

	&:not(:last-child) {
		margin-bottom: 16px;
	}

	.featureCheck {
		display: inline-flex;
		margin-right: 16px;
		align-items: center;
	}
}

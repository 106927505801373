@import (reference) '~vars.less';

.label {
	display: inline-flex;
	//align-items: flex-end;
	text-align: left;
	color: @space_blue_600;
	cursor: pointer;
	.text {
		flex: 1 0 auto;
		#regular > .small;
		margin-left: 8px;
		position: relative;
		top: 1px;
	}
	&.error {
		color: @warning_red_500;
		.checkbox .checkmark {
			border-color: @warning_red_300;
			background: @warning_red_100;
		}
	}
}
.radio {
	position: relative;
	min-width: 18px;
	height: 18px;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	.disabled {
		cursor: not-allowed;
	}

	input {
		display: none;

		&:checked ~ .checkmark:after {
			display: block;
		}
		&:checked ~ .checkmark.primary {
			&:after {
				background: @brand_500;
			}
			&.disabled:after {
				background: lighten(@brand_500, 10%);
			}
		}
		&:checked ~ .checkmark.filters_primary {
			background: @brand_500;
			border-width: 0;
			&:after {
				content: '';
				position: absolute;
				border-radius: 0;
				left: 6px;
				top: 3px;
				width: 4px;
				height: 8px;
				border: solid;
				border-color: @white;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			&.disabled:after {
				background: lighten(@brand_500, 10%);
			}
		}
		&:checked ~ .checkmark.drive,
		&:checked ~ .checkmark.sheets,
		&:checked ~ .checkmark.dropbox,
		&:checked ~ .checkmark.onedrive {
			border-width: 0;
			&:after {
				content: '';
				position: absolute;
				border-radius: 0;
				left: 7px;
				top: 4px;
				width: 2px;
				height: 6px;
				border: solid;
				border-color: @white;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
		&:checked ~ .checkmark.drive {
			background: @drive;
			&.disabled:after {
				background: lighten(@drive, 10%);
			}
		}
		&:checked ~ .checkmark.sheets {
			background: @sheets;
			&.disabled:after {
				background: lighten(@sheets, 10%);
			}
		}
		&:checked ~ .checkmark.dropbox {
			background: @dropbox;
			&.disabled:after {
				background: lighten(@dropbox, 10%);
			}
		}
		&:checked ~ .checkmark.onedrive {
			background: @onedrive;
			&.disabled:after {
				background: lighten(@onedrive, 10%);
			}
		}

		&:checked ~ .checkmark.success {
			&:after {
				background: @success_green_500;
			}
			&.disabled:after {
				background: lighten(@success_green_500, 10%);
			}
		}
		&:checked ~ .checkmark.dark {
			&:after {
				background: @brand_600;
			}
			&.disabled:after {
				background: lighten(@brand_600, 10%);
			}
		}
		&:checked ~ .checkmark.light {
			&:after {
				background: @attention_orange_500;
			}
			&.disabled:after {
				background: lighten(@attention_orange_500, 10%);
			}
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 18px;
		width: 18px;
		border: 2px solid;
		border-radius: 50%;

		&:after {
			content: '';
			position: absolute;
			display: none;
			top: 2px;
			left: 2px;
			width: 10px;
			height: 10px;
			border-radius: 50%;
		}

		&.primary {
			border-color: @brand_500;
		}
		&.primary.disabled {
			border-color: lighten(@brand_500, 10%);
		}
		&.filters_primary {
			border-color: @space_blue_300;
			border-width: 1px;
		}
		&.filters_primary.disabled {
			border-color: @space_blue_100;
		}
		&.drive,
		&.sheets,
		&.onedrive,
		&.dropbox {
			border-color: @space_blue_200;
			border-width: 1px;
		}
		&.drive.disabled,
		&.sheets.disabled,
		&.onedrive.disabled,
		&.dropbox.disabled {
			border-color: lighten(@space_blue_200, 10%);
		}
		&.success {
			border-color: @success_green_500;
		}
		&.success.disabled {
			border-color: lighten(@success_green_500, 10%);
		}
		&.dark {
			border-color: @brand_600;
		}
		&.dark.disabled {
			border-color: lighten(@brand_600, 10%);
		}
		&.light {
			border-color: @attention_orange_500;
		}
		&.light.disabled {
			border-color: lighten(@attention_orange_500, 10%);
		}
	}
}

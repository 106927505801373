@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?fhzw4m');
  src:  url('fonts/icomoon.eot?fhzw4m#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fhzw4m') format('truetype'),
    url('fonts/icomoon.woff?fhzw4m') format('woff'),
    url('fonts/icomoon.svg?fhzw4m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-important-small-16:before {
  content: "\ea5a";
}
.icon-align-center-1-16:before {
  content: "\ea57";
}
.icon-align-left-1-16:before {
  content: "\ea58";
}
.icon-align-right-1-16:before {
  content: "\ea59";
}
.icon-play-16:before {
  content: "\ea56";
}
.icon-folder-16:before {
  content: "\e959";
}
.icon-premium-16:before {
  content: "\ea55";
}
.icon-survey-management-small-16:before {
  content: "\ea4e";
}
.icon-premium-small-16:before {
  content: "\ea4f";
}
.icon-my-account-small-16:before {
  content: "\ea50";
}
.icon-members-small-16:before {
  content: "\ea51";
}
.icon-members-activity-small-16:before {
  content: "\ea52";
}
.icon-log-out-16:before {
  content: "\ea53";
}
.icon-home-16:before {
  content: "\ea54";
}
.icon-page-thin-16:before {
  content: "\ea4d";
}
.icon-enter-16:before {
  content: "\ea4c";
}
.icon-go-to-16:before {
  content: "\ea4b";
}
.icon-question-branching-16:before {
  content: "\ea49";
}
.icon-Move-small-16:before {
  content: "\ea4a";
}
.icon-video-24:before {
  content: "\ea47";
}
.icon-minus-16:before {
  content: "\ea48";
}
.icon-warning-outline-large-24:before {
  content: "\ea46";
}
.icon-segmentation-year-24:before {
  content: "\e9dd";
}
.icon-segmentation-business-24:before {
  content: "\e9dc";
}
.icon-segmentation-calendar-24:before {
  content: "\ea09";
}
.icon-segmentation-feedback-24:before {
  content: "\ea37";
}
.icon-segmentation-irregularly-24:before {
  content: "\ea3d";
}
.icon-segmentation-mix-24:before {
  content: "\ea3e";
}
.icon-segmentation-more-24:before {
  content: "\ea3f";
}
.icon-segmentation-one-24:before {
  content: "\ea40";
}
.icon-segmentation-organisation-24:before {
  content: "\ea41";
}
.icon-segmentation-personal-24:before {
  content: "\ea42";
}
.icon-segmentation-student-24:before {
  content: "\ea43";
}
.icon-segmentation-team-24:before {
  content: "\ea44";
}
.icon-segmentation-up-24:before {
  content: "\ea45";
}
.icon-advanced-24:before {
  content: "\e99e";
}
.icon-analyse-24:before {
  content: "\ea32";
}
.icon-folder-opened-24:before {
  content: "\ea33";
}
.icon-individual-responses-24:before {
  content: "\ea34";
}
.icon-integrations-24:before {
  content: "\ea35";
}
.icon-members-24:before {
  content: "\ea36";
}
.icon-members-activity-24:before {
  content: "\ea38";
}
.icon-my_account-24:before {
  content: "\ea39";
}
.icon-premium-24:before {
  content: "\ea3a";
}
.icon-security-24:before {
  content: "\ea3b";
}
.icon-survey-management-24:before {
  content: "\ea3c";
}
.icon-success-24:before {
  content: "\ea30";
}
.icon-warning-large-24:before {
  content: "\ea31";
}
.icon-survey-24:before {
  content: "\ea2f";
}
.icon-ai-16:before {
  content: "\ea2e";
}
.icon-email-16:before {
  content: "\ea2d";
}
.icon-plus-small-16:before {
  content: "\ea2c";
}
.icon-heart-40:before {
  content: "\ea29";
}
.icon-like-40:before {
  content: "\ea2a";
}
.icon-star-40:before {
  content: "\ea2b";
}
.icon-Replace-16:before {
  content: "\ea28";
}
.icon-arrow-down-16:before {
  content: "\e930";
}
.icon-arrow-left-16:before {
  content: "\e9ae";
}
.icon-arrow-right-16:before {
  content: "\e9af";
}
.icon-arrow-up-16:before {
  content: "\e9b0";
}
.icon-close-small-16:before {
  content: "\e9b1";
}
.icon-like-16-01:before {
  content: "\ea1e";
}
.icon-check-bold-16:before {
  content: "\ea1f";
}
.icon-close-circle-16:before {
  content: "\ea20";
}
.icon-cut-16:before {
  content: "\ea21";
}
.icon-design-16:before {
  content: "\ea22";
}
.icon-font-size-16:before {
  content: "\ea23";
}
.icon-heart-16:before {
  content: "\ea24";
}
.icon-link-thin-16:before {
  content: "\ea25";
}
.icon-star-16:before {
  content: "\ea26";
}
.icon-submit-16:before {
  content: "\ea27";
}
.icon-down-16:before {
  content: "\ea1b";
}
.icon-up-16:before {
  content: "\ea1c";
}
.icon-ai-24:before {
  content: "\ea1d";
}
.icon-warning-triangle-16:before {
  content: "\ea1a";
}
.icon-youtube-16:before {
  content: "\ea19";
}
.icon-play-circle-24:before {
  content: "\ea18";
}
.icon-form-24:before {
  content: "\ea01";
}
.icon-nps-24:before {
  content: "\ea02";
}
.icon-custom-text-24:before {
  content: "\ea03";
}
.icon-date-answer-24:before {
  content: "\ea04";
}
.icon-divider-24:before {
  content: "\ea05";
}
.icon-dropdown-24:before {
  content: "\ea06";
}
.icon-email-answer-24:before {
  content: "\ea07";
}
.icon-emoji-rating-24:before {
  content: "\ea08";
}
.icon-img-24:before {
  content: "\ea0a";
}
.icon-matrix-24:before {
  content: "\ea0b";
}
.icon-multiple-choice-24:before {
  content: "\ea0c";
}
.icon-number-answer-24:before {
  content: "\ea0d";
}
.icon-number-rating-24:before {
  content: "\ea0e";
}
.icon-opt-in-checkbox-24:before {
  content: "\ea0f";
}
.icon-ordering-24:before {
  content: "\ea10";
}
.icon-picture-choice-24:before {
  content: "\ea11";
}
.icon-rating-scale-24:before {
  content: "\ea12";
}
.icon-semantic-differential-24:before {
  content: "\ea13";
}
.icon-single-choice-24:before {
  content: "\ea14";
}
.icon-star-rating-24:before {
  content: "\ea15";
}
.icon-text-answer-24:before {
  content: "\ea16";
}
.icon-title-24:before {
  content: "\ea17";
}
.icon-form-20:before {
  content: "\e9f0";
}
.icon-nps-20:before {
  content: "\e9f1";
}
.icon-date-answer-20:before {
  content: "\e9f2";
}
.icon-dropdown-20:before {
  content: "\e9f3";
}
.icon-email-answer-20:before {
  content: "\e9f4";
}
.icon-emoji-rating-20:before {
  content: "\e9f5";
}
.icon-matrix-20:before {
  content: "\e9f6";
}
.icon-multiple-choice-20:before {
  content: "\e9f7";
}
.icon-number-answer-20:before {
  content: "\e9f8";
}
.icon-number-rating-20:before {
  content: "\e9f9";
}
.icon-ordering-20:before {
  content: "\e9fa";
}
.icon-picture-choice-20:before {
  content: "\e9fb";
}
.icon-rating_scale-20:before {
  content: "\e9fc";
}
.icon-semantic_differential-20:before {
  content: "\e9fd";
}
.icon-single-choice-20:before {
  content: "\e9fe";
}
.icon-star-rating-20:before {
  content: "\e9ff";
}
.icon-text-answer-20:before {
  content: "\ea00";
}
.icon-form-16:before {
  content: "\e9ef";
}
.icon-emoji-rating-16:before {
  content: "\e9db";
}
.icon-custom-text-16:before {
  content: "\e9de";
}
.icon-date-answer-16:before {
  content: "\e9df";
}
.icon-dropdown-16:before {
  content: "\e9e0";
}
.icon-email-answer-16:before {
  content: "\e9e1";
}
.icon-illustrative-image-16:before {
  content: "\e9e2";
}
.icon-matrix-16:before {
  content: "\e9e3";
}
.icon-multiple-choice-16:before {
  content: "\e9e4";
}
.icon-nps-16:before {
  content: "\e9e5";
}
.icon-number-answer-16:before {
  content: "\e9e6";
}
.icon-number-rating-16:before {
  content: "\e9e7";
}
.icon-ordering-16:before {
  content: "\e9e8";
}
.icon-picture-choice-16:before {
  content: "\e9e9";
}
.icon-pop-up-16:before {
  content: "\e9ea";
}
.icon-rating-scale-16:before {
  content: "\e9eb";
}
.icon-semantic-differential-16:before {
  content: "\e9ec";
}
.icon-star-rating-16:before {
  content: "\e9ed";
}
.icon-text-answer-16:before {
  content: "\e9ee";
}
.icon-stacked-graph-horizontal-16:before {
  content: "\e9d9";
}
.icon-stacked-graph-vertical-16:before {
  content: "\e9da";
}
.icon-info-circle-24:before {
  content: "\e9d8";
}
.icon-quotes-32:before {
  content: "\e9d7";
}
.icon-secured-24:before {
  content: "\e9b2";
}
.icon-large-check-16:before {
  content: "\e9d5";
}
.icon-send-email-24:before {
  content: "\e9d6";
}
.icon-messenger-16:before {
  content: "\e9d4";
}
.icon-jpg-16:before {
  content: "\e9d2";
}
.icon-print-16:before {
  content: "\e9d3";
}
.icon-new-tab-minimalistic-16:before {
  content: "\e9d1";
}
.icon-next-16:before {
  content: "\e9ce";
}
.icon-az-16:before {
  content: "\e9cf";
}
.icon-za-16:before {
  content: "\e9d0";
}
.icon-arrow-right-medium-16:before {
  content: "\e9cd";
}
.icon-share-16:before {
  content: "\e9cc";
}
.icon-from-scratch-24:before {
  content: "\e9ca";
}
.icon-from-template-24:before {
  content: "\e9cb";
}
.icon-minus-large-16:before {
  content: "\e9c9";
}
.icon-close-medium-24:before {
  content: "\e9c8";
}
.icon-preview-24:before {
  content: "\e9c2";
}
.icon-nps-40:before {
  content: "\e9c7";
}
.icon-share-24:before {
  content: "\e9c6";
}
.icon-checkmark-24:before {
  content: "\e9c5";
}
.icon-hidden-24:before {
  content: "\e9c4";
}
.icon-questionnaire-24:before {
  content: "\e991";
}
.icon-website-24:before {
  content: "\e9bb";
}
.icon-tutorial-24:before {
  content: "\e9bc";
}
.icon-add-image-24:before {
  content: "\e9bd";
}
.icon-individual-24:before {
  content: "\e9be";
}
.icon-link-24:before {
  content: "\e9bf";
}
.icon-plus-large-24:before {
  content: "\e9c0";
}
.icon-restart-24:before {
  content: "\e9c1";
}
.icon-hidden-16:before {
  content: "\e9ba";
}
.icon-help-center-16:before {
  content: "\e9b7";
}
.icon-add-24:before {
  content: "\e9b8";
}
.icon-play-24:before {
  content: "\e9b9";
}
.icon-order-16:before {
  content: "\e99b";
}
.icon-alphabetically-16:before {
  content: "\e93e";
}
.icon-calendar-16:before {
  content: "\e92d";
}
.icon-grid-16:before {
  content: "\e92e";
}
.icon-list-16:before {
  content: "\e92f";
}
.icon-arrow-right-small-16:before {
  content: "\e92b";
}
.icon-arrow-down-small-16:before {
  content: "\e92c";
}
.icon-image-filled-24:before {
  content: "\e9ab";
}
.icon-img-16:before {
  content: "\e9ac";
}
.icon-study-outline-16:before {
  content: "\e9ad";
}
.icon-locked-small-16:before {
  content: "\e9b3";
}
.icon-lock-minimalistic-24:before {
  content: "\e9b4";
}
.icon-lock-minimalistic-small-24:before {
  content: "\e9b5";
}
.icon-warning-minimalistic-24:before {
  content: "\e9b6";
}
.icon-dashboard-24:before {
  content: "\e99c";
}
.icon-download-filled-24:before {
  content: "\e99d";
}
.icon-design-24:before {
  content: "\e99f";
}
.icon-exit-page-24:before {
  content: "\e9a0";
}
.icon-performance-24:before {
  content: "\e9a1";
}
.icon-summary-results-24:before {
  content: "\e9a2";
}
.icon-send-outline-16:before {
  content: "\e9a3";
}
.icon-align-center-16:before {
  content: "\e9a4";
}
.icon-align-left-16:before {
  content: "\e9a5";
}
.icon-align-right-16:before {
  content: "\e9a6";
}
.icon-bold-16:before {
  content: "\e9a7";
}
.icon-italic-16:before {
  content: "\e9a8";
}
.icon-link-2-16:before {
  content: "\e9a9";
}
.icon-send-lg-16:before {
  content: "\e9aa";
}
.icon-cloud-saved-24:before {
  content: "\e99a";
}
.icon-warning-outlined-16:before {
  content: "\e999";
}
.icon-info-filled-24:before {
  content: "\e998";
}
.icon-custom-text-40:before {
  content: "\e98c";
}
.icon-image-choice-40:before {
  content: "\e98d";
}
.icon-matrix-multiple-choice-40:before {
  content: "\e98e";
}
.icon-matrix-single-choice-40:before {
  content: "\e98f";
}
.icon-multi-choice-40:before {
  content: "\e990";
}
.icon-ordering-40:before {
  content: "\e992";
}
.icon-nps-32:before {
  content: "\e9c3";
}
.icon-rating-scale-40:before {
  content: "\e993";
}
.icon-semantic-differential-40:before {
  content: "\e994";
}
.icon-single-choice-40:before {
  content: "\e995";
}
.icon-star-rating-40:before {
  content: "\e996";
}
.icon-text-answer-40:before {
  content: "\e997";
}
.icon-locked-user-24:before {
  content: "\e98b";
}
.icon-manage-surveys-24:before {
  content: "\e98a";
}
.icon-calendar-24:before {
  content: "\e989";
}
.icon-change-owner-16:before {
  content: "\e987";
}
.icon-increase-team-16:before {
  content: "\e988";
}
.icon-expand-24:before {
  content: "\e985";
}
.icon-summary-24:before {
  content: "\e986";
}
.icon-create-40:before {
  content: "\e984";
}
.icon-analyze-96:before {
  content: "\e981";
}
.icon-info-96:before {
  content: "\e982";
}
.icon-responses-96:before {
  content: "\e983";
}
.icon-lock-small-16:before {
  content: "\e97d";
}
.icon-rename-16:before {
  content: "\e97e";
}
.icon-send-email-16:before {
  content: "\e97f";
}
.icon-very-small-arrow-down-16:before {
  content: "\e980";
}
.icon-dashboards-96:before {
  content: "\e919";
}
.icon-analyze-24:before {
  content: "\e97a";
}
.icon-dashboards-24:before {
  content: "\e97b";
}
.icon-responses-24:before {
  content: "\e97c";
}
.icon-cart-24:before {
  content: "\e974";
}
.icon-send-16:before {
  content: "\e975";
}
.icon-slash-16:before {
  content: "\e976";
}
.icon-Move-16:before {
  content: "\e966";
}
.icon-previous-page-16:before {
  content: "\e967";
}
.icon-next-page-16:before {
  content: "\e968";
}
.icon-bar-16:before {
  content: "\e969";
}
.icon-columns-16:before {
  content: "\e96a";
}
.icon-donut-16:before {
  content: "\e96b";
}
.icon-NPS-16:before {
  content: "\e96c";
}
.icon-pie-16:before {
  content: "\e96d";
}
.icon-radar-16:before {
  content: "\e96e";
}
.icon-Reset-16:before {
  content: "\e96f";
}
.icon-table-16:before {
  content: "\e970";
}
.icon-wordcloud-16:before {
  content: "\e971";
}
.icon-info-small-16:before {
  content: "\e972";
}
.icon-vector-16:before {
  content: "\e900";
}
.icon-svg-24:before {
  content: "\e901";
}
.icon-activity-member-96:before {
  content: "\e902";
}
.icon-folder-96:before {
  content: "\e903";
}
.icon-inovoice-96:before {
  content: "\e904";
}
.icon-payment-96:before {
  content: "\e905";
}
.icon-preview-96:before {
  content: "\e906";
}
.icon-search-results-96:before {
  content: "\e907";
}
.icon-settings-96:before {
  content: "\e908";
}
.icon-document-40:before {
  content: "\e909";
}
.icon-image-40:before {
  content: "\e90a";
}
.icon-account-24:before {
  content: "\e90b";
}
.icon-activity-member-24:before {
  content: "\e90c";
}
.icon-arrow-back-24:before {
  content: "\e90d";
}
.icon-arrow-left-24:before {
  content: "\e90e";
}
.icon-arrow-right-24:before {
  content: "\e90f";
}
.icon-change-24:before {
  content: "\e910";
}
.icon-chat-24:before {
  content: "\e911";
}
.icon-chevron-right-24:before {
  content: "\e912";
}
.icon-code-24:before {
  content: "\e914";
}
.icon-create-24:before {
  content: "\e915";
}
.icon-delete-24:before {
  content: "\e916";
}
.icon-document-24:before {
  content: "\e917";
}
.icon-download-24:before {
  content: "\e918";
}
.icon-email-24:before {
  content: "\e91a";
}
.icon-preview-filled-16:before {
  content: "\e977";
}
.icon-eye-24:before {
  content: "\e91b";
}
.icon-image-24:before {
  content: "\e91c";
}
.icon-image-large-24:before {
  content: "\e91d";
}
.icon-info-24:before {
  content: "\e91e";
}
.icon-invite-cancellation-24:before {
  content: "\e91f";
}
.icon-invite-sendings-24:before {
  content: "\e920";
}
.icon-login-24:before {
  content: "\e921";
}
.icon-more-down-24:before {
  content: "\e922";
}
.icon-new-tab-24:before {
  content: "\e923";
}
.icon-payment-24:before {
  content: "\e924";
}
.icon-plus-24:before {
  content: "\e925";
}
.icon-registration-24:before {
  content: "\e926";
}
.icon-settings-24:before {
  content: "\e927";
}
.icon-team-24:before {
  content: "\e928";
}
.icon-team-join-24:before {
  content: "\e929";
}
.icon-warning-24:before {
  content: "\e92a";
}
.icon-close-24:before {
  content: "\e913";
}
.icon-close-very-small-16:before {
  content: "\e931";
}
.icon-close-16:before {
  content: "\e932";
}
.icon-copy-16:before {
  content: "\e933";
}
.icon-delete-16:before {
  content: "\e934";
}
.icon-download-16:before {
  content: "\e935";
}
.icon-edit-16:before {
  content: "\e936";
}
.icon-filter-16:before {
  content: "\e937";
}
.icon-help-16:before {
  content: "\e938";
}
.icon-image-16:before {
  content: "\e939";
}
.icon-info-16:before {
  content: "\e93a";
}
.icon-info-large-16:before {
  content: "\e93b";
}
.icon-large-arrow-left-16:before {
  content: "\e93c";
}
.icon-large-arrow-right-16:before {
  content: "\e93d";
}
.icon-link-16:before {
  content: "\e93f";
}
.icon-new-tab-16:before {
  content: "\e940";
}
.icon-plus-16:before {
  content: "\e941";
}
.icon-plus-large-16:before {
  content: "\e942";
}
.icon-preview-16:before {
  content: "\e943";
}
.icon-resend-16:before {
  content: "\e944";
}
.icon-search-16:before {
  content: "\e945";
}
.icon-small-check-16:before {
  content: "\e946";
}
.icon-turn-off-16:before {
  content: "\e947";
}
.icon-warning-circle-16:before {
  content: "\e973";
}
.icon-warning-circle-small-16:before {
  content: "\e978";
}
.icon-warning-16:before {
  content: "\e948";
}
.icon-lock-40:before {
  content: "\e949";
}
.icon-clear-24:before {
  content: "\e94a";
}
.icon-facebook-24:before {
  content: "\e94b";
}
.icon-folder-24:before {
  content: "\e94c";
}
.icon-linkedin-24:before {
  content: "\e94d";
}
.icon-more-24:before {
  content: "\e94e";
}
.icon-png-24:before {
  content: "\e94f";
}
.icon-Skip-24:before {
  content: "\e950";
}
.icon-twitter-24:before {
  content: "\e951";
}
.icon-whatsapp-24:before {
  content: "\e952";
}
.icon-document-16:before {
  content: "\e953";
}
.icon-more-circle-16:before {
  content: "\e954";
}
.icon-chevrondown-16:before {
  content: "\e955";
}
.icon-close-invert-16:before {
  content: "\e956";
}
.icon-dashboard-16:before {
  content: "\e957";
}
.icon-facebook-16:before {
  content: "\e958";
}
.icon-graph-16:before {
  content: "\e95a";
}
.icon-linkedin-16:before {
  content: "\e95b";
}
.icon-locked-16:before {
  content: "\e95c";
}
.icon-more-16:before {
  content: "\e95d";
}
.icon-more-large-16:before {
  content: "\e95e";
}
.icon-paint-16:before {
  content: "\e95f";
}
.icon-png-16:before {
  content: "\e960";
}
.icon-quotes-16:before {
  content: "\e961";
}
.icon-settings-16:before {
  content: "\e962";
}
.icon-skip-16:before {
  content: "\e963";
}
.icon-small-arrow-16:before {
  content: "\e964";
}
.icon-very-small-arrow-16:before {
  content: "\e979";
}
.icon-twitter-16:before {
  content: "\e965";
}

@import (reference) '~vars.less';

:global(.no-touch) {
	.cross:hover {
		color: @white !important;
	}
}

.modal {
	z-index: 99999 !important;
}

.container {
	flex: 1 0 auto;
	display: flex;
	margin: 0 -40px;
	text-align: left;
}

.content {
	flex: 1 0;
	display: flex;
	flex-direction: column;
	padding: 33px 25px;
}

.title {
	.h2('mobile');
	margin-bottom: 5px;
	color: @space_blue_600;
}

.subtitle {
	#regular > .medium;
	margin-bottom: 36px;
	color: @space_blue_500;
}

.line {
	border-bottom: 1px solid @grey_200;
	padding-bottom: 16px;
	margin-bottom: 16px;
	cursor: pointer;

	&:last-child {
		border-color: transparent;
	}
}

.flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.radio {
	margin-right: 8px;
	transform: translateY(4px);
}

.name {
	#regular > .medium;
	color: @space_blue_600;
}

.column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.purple {
	color: @brand_500;
}

.amount {
	#medium > .medium;
	margin: 3px 0;
	white-space: nowrap;
	color: @space_blue_600;
}

.label {
	#medium > .medium;
	color: @white;
	background: @success_green_500;
	padding: 4px 7px;
	margin: 8px 0 0 26px;
	position: relative;
	display: inline-block;
	.radius(5px);

	&:after {
		content: '';
		position: absolute;
		left: 6px;
		bottom: calc(100% - 8px);
		border: 15px solid transparent;
		border-bottom-color: @success_green_500;
	}
}

.help {
	margin: 0 16px 0 18px;
}

.separator {
	margin: 16px 0;
	border-top: 2px solid #c5d0de;
}

.total {
	#medium > .medium;
	color: @space_blue_600;
}

.vat {
	#regular > .small();
	color: @space_blue_500;
}

.sum {
	.h3;
	text-align: right;
}

.checkbox {
	margin-top: 24px;
}

.security {
	#medium > .small;
	display: flex;
	flex-direction: column;
	max-width: 185px;
	margin: 20px auto 14px auto;
	text-align: center;
	color: @success_green_500;

	.icon {
		font-size: 30px;
		margin-bottom: 8px;
	}
}

.features {
	display: none;
	width: 271px;
	padding: 54px 28px;
	text-align: center;
	background: fade(@black, 86%);

	.image {
		width: auto;
		height: 82px;
		margin-bottom: 24px;
		border: 3px solid #c4c4c4;
		.radius(41px);
		align-self: center;
	}

	.feedback {
		#mediumItalic > .small;
		color: @white;
		margin-bottom: 24px;
	}

	.user {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 58px;

		.name {
			margin-bottom: 8px;
			#medium > .small;
			color: @white;
		}

		.separator {
			width: 3px;
			height: 3px;
			margin: 0 10px;
			background: fade(@white, 40%);
			.radius(50%);
		}

		.company {
			#regular > .small;
			text-decoration: none;
			color: @space_blue_200;
		}
	}
}

.footer {
	display: flex;
	justify-content: flex-end;
	margin-top: 48px;

	.button {
		margin-right: 16px;
	}
}

@media (min-width: 480px) {
	.content {
		padding: 33px 35px;

		.title {
			.h1('mobile');
		}

		.name {
			#regular > .large;
		}

		.amount {
			#medium > .large;
		}

		.total {
			.h3;
		}

		.sum {
			.h3;
		}
	}
}

@media (min-width: 661px) {
	.content {
		.security {
			display: none;
		}
	}

	.features {
		display: block;

		.security {
			color: @success_green_400;
		}
	}
}

@media (min-width: 840px) {
	.content {
		padding: 40px;

		.title {
			.h1;
		}

		.total {
			.h3;
		}

		.sum {
			.h3;
		}
	}

	.security {
		flex-direction: row;
		align-items: center;
		text-align: left;
		max-width: 225px;
		align-self: flex-end;
		margin-top: auto;

		.icon {
			margin: 0 12px 0 0;
		}
	}

	.features {
		width: 310px;
		padding: 37px 39px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

@media (min-width: 1367px) {
	.column {
		flex-direction: row;
	}

	.label {
		margin: 0 0 0 22px;
		.radius(0 5px 5px 0);

		&:after {
			left: unset;
			right: 100%;
			bottom: 0;
			border: 13px solid transparent;
			border-right-color: @success_green_500;
		}
	}
}

.increaseTeamSize {
	.title {
		margin-bottom: 47px;
	}

	.additionalSeats {
		margin-right: 16px;
	}

	.section {
		display: flex;
		padding: 24px 28px;
		margin: 20px 0 25px;
		border-radius: 10px;
		border: 1px solid #ebebeb;

		.half {
			width: 50%;

			.sectionTitle {
				margin-bottom: 8px;
			}
		}
	}

	.totalPrice {
		.h2();
		margin-bottom: 8px;
		color: @space_blue_600;
	}

	.divider {
		margin: 25px 0 18px;
		border-top: 1px solid #ebebeb;
	}

	.checkbox {
		margin-bottom: 44px;
	}
}

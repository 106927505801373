@import (reference) '~vars.less';

.modalContent {
	:global(.styledBody) {
		padding: 0 !important;
	}
}

.content {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 32px 0;
}

.left {
	width: 464px;
	margin-right: 88px;

	.radio {
		margin: 0 -16px;
	}

	.divider {
		margin: 8px 0 20px;
		border-top: 1px solid @grey_shades_with_blue_100;
	}

	.total {
		display: flex;
	}

	.footer {
		display: flex;
		align-items: center;
	}
}

.right {
	width: 332px;
	padding: 40px;
	border-radius: 24px;
	border: 2px solid @grey_new_300;

	.user {
		display: flex;
		align-items: center;
	}
}

.sectionTitle {
	padding: 16px 0 12px;
}

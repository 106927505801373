@import (reference) '~vars.less';

.h1(@viewport:'desktop') {
	& when (@viewport = 'mobile') {
		letter-spacing: -0.01em;
		font-size: 23px;
		line-height: 130%;
	}
	& when (@viewport = 'desktop') {
		letter-spacing: 0.015em;
		font-size: 28px;
		line-height: 120%;
	}
	font-family: @semibold;
	font-weight: normal;
}
.h2(@viewport:'desktop') {
	& when (@viewport = 'mobile') {
		font-size: 20px;
		line-height: 140%;
	}
	& when (@viewport = 'desktop') {
		font-size: 22px;
		line-height: 170%;
		letter-spacing: 0.015em;
	}
	font-family: @semibold;
	font-weight: normal;
}
.h3(@viewport:'desktop') {
	& when (@viewport = 'mobile') {
		line-height: 140%;
		letter-spacing: 0.01em;
	}
	& when (@viewport = 'desktop') {
		line-height: 170%;
		letter-spacing: 0.015em;
	}
	font-family: @semibold;
	font-weight: normal;
	font-size: 16px;
}
.h4() {
	font-family: @semibold;
	font-weight: normal;
	font-size: 13px;
	line-height: 180%;
	text-transform: uppercase;
}
.h5() {
	font-family: @semibold;
	font-weight: normal;
	font-size: 12px;
	line-height: 180%;
	text-transform: uppercase;
}
.h6() {
	font-family: @semibold;
	font-weight: normal;
	font-size: 10px;
	line-height: 180%;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}
#regular {
	.small() {
		font-family: @regular;
		font-size: 12px;
		line-height: 160%;
		letter-spacing: 0.006em;
	}
	.medium() {
		font-family: @regular;
		font-size: 13px;
		line-height: 180%;
		letter-spacing: 0.006em;
	}
	.large() {
		font-family: @regular;
		font-size: 15px;
		line-height: 180%;
		letter-spacing: 0.006em;
	}
}
#medium {
	.small() {
		font-family: @semibold;
		font-size: 12px;
		line-height: 140%;
		letter-spacing: 0.006em;
	}
	.medium() {
		font-family: @semibold;
		font-size: 13px;
		line-height: 140%;
		letter-spacing: 0.006em;
	}
	.large() {
		font-family: @semibold;
		font-size: 15px;
		line-height: 140%;
		letter-spacing: 0.006em;
	}
}
#mediumItalic {
	.small() {
		font-family: @semibolditalic;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: 0.006em;
	}
	.medium() {
		font-family: @semibolditalic;
		font-size: 13px;
		line-height: 150%;
		letter-spacing: 0.006em;
	}
	.large() {
		font-family: @semibolditalic;
		font-size: 15px;
		line-height: 150%;
		letter-spacing: 0.006em;
	}
}
#bold {
	.medium() {
		font-family: @bold;
		font-size: 13px;
		line-height: 180%;
		letter-spacing: -0.01em;
	}
}
​ #ui {
	.largeButtonLabel() {
		font-family: @bold;
		font-size: 13px;
		line-height: 196.8%;
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}
	.labelSmall() {
		font-size: 10px;
		line-height: 196%;
		letter-spacing: 0.8px;
		font-family: @semibold;
		text-transform: uppercase;
	}
	.textButtonLabel() {
		font-size: 12px;
		line-height: 180%;
		letter-spacing: 0.5px;
		font-family: @semibold;
		text-transform: uppercase;
	}
	.buttonLabel() {
		font-size: 11px;
		line-height: 180%;
		letter-spacing: 0.5px;
		font-family: @bold;
		text-transform: uppercase;
	}
}
​ #display {
	.level1() {
		font-size: 96px;
		line-height: 180%;
		font-family: @semibold;
	}
	.level2() {
		font-size: 72px;
		line-height: 180%;
		font-family: @semibold;
	}
	.level3() {
		font-size: 50px;
		line-height: 180%;
		font-family: @semibold;
	}
	.level4() {
		font-size: 34px;
		line-height: 180%;
		font-family: @semibold;
	}
}

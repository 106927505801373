@import (reference) '~vars.less';

.back {
	#regular > .medium();
	padding: 1px 3px 2px 1px;
	color: @space_blue_600;
	display: inline;
	cursor: pointer;
	.transition(color, 150ms);
	&:hover {
		color: @brand_500;
	}
	.icon {
		margin-right: 17px;
		font-size: 15px;
	}
}

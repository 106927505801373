@import (reference) '~vars.less';
.label {
	display: inline-flex;
	//align-items: flex-end;
	text-align: left;
	color: @space_blue_500;
	cursor: pointer;
	.text {
		#regular > .small;
		margin-left: 8px;
		position: relative;
		top: 1px;
	}
	&.error {
		color: @warning_red_500;
		.checkbox .checkmark {
			border-color: @warning_red_300;
			//background: @warning_red_100;
		}
	}
	label {
		padding: 0;
	}
}
.checkbox {
	position: relative;
	width: 18px;
	height: 18px;
	cursor: pointer;
	display: inline-flex;
	vertical-align: middle;
	align-self: self-start;
	margin-top: 2px;
	flex-shrink: 0;
	.disabled {
		cursor: not-allowed;
	}

	input {
		display: none;

		&:checked ~ .checkmark {
			&.primary {
				border-color: @brand_500;
				background-color: @brand_500;
				&.disabled {
					background-color: lighten(@brand_500, 10%);
				}
			}
			&.filters_primary {
				background-color: @brand_500;
				border-width: 0;

				&.disabled {
					background-color: lighten(@brand_500, 10%);
				}
			}
			&.individual_responses {
				background-color: @brand_500;
				border-width: 0;

				&.disabled {
					background-color: lighten(@brand_500, 10%);
				}
			}
			&.success {
				background-color: @success_green_500;
				&.disabled {
					background-color: lighten(@success_green_500, 10%);
				}
			}
			&.dark {
				background-color: @space_blue_600;
				&.disabled {
					background-color: @space_blue_600; //lighten(@brand_600, 10%);
				}
			}
			&.light {
				background-color: @white;
				border-color: @white;
				&.disabled {
					background-color: #fef7f1;
					border-color: #fef7f1;
				}
			}
			&.purple {
				background-color: @grey_300;
				border-color: @grey_300;
				&.disabled {
					background-color: @space_blue_300;
					border-color: @space_blue_300;
				}
			}
		}

		&:checked ~ .checkmark:after {
			display: block;
		}
		&:checked ~ .checkmark.light:after {
			border-color: @attention_orange_500;
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid;
		background: @white;
		border-radius: 30%;

		&:after {
			content: '';
			position: absolute;
			display: none;
			left: 5px;
			top: 1px;
			width: 4px;
			height: 8px;
			border: solid;
			border-color: @white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&.primary {
			border-color: @space_blue_300;
		}
		&.primary.disabled {
			border-color: lighten(@brand_500, 10%);
		}
		&.filters_primary {
			border-color: @space_blue_200;
			border-width: 1px;
			border-radius: 5px;
			&:after {
				left: 7px;
				top: 4px;
				width: 2px;
				height: 6px;
			}
		}
		&.success {
			border-color: @success_green_500;
		}
		&.success.disabled {
			border-color: lighten(@success_green_500, 10%);
		}
		&.dark {
			border-color: @brand_600;
		}
		&.dark.disabled {
			border-color: lighten(@brand_600, 10%);
		}
		&.light {
			border-color: @attention_orange_500;
		}
		&.light.disabled {
			border-color: lighten(@attention_orange_500, 10%);
		}
		&.purple {
			border-color: @grey_300;
		}
		&.purple.disabled {
			border-color: @space_blue_300;
		}
		&.individual_responses {
			border-color: @space_blue_300;
			background: @white;
			border-width: 1px;
			border-radius: 5px;
			&:after {
				left: 7px;
				top: 3px;
				width: 3px;
				height: 8px;
			}
		}
		&.individual_responses.disabled {
			border-color: greenyellow;
		}
	}
}

@import (reference) '~vars.less';

:global(#overlay) {
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 99999;
	> div {
		height: 100%;
	}
	.outside {
		position: fixed;
		height: 100%;
		width: 100%;
		display: table;
		.modal {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			margin: auto;
			background-color: @white;
			width: 100%;
			max-width: 600px;
			position: relative;
			.radius(15px);
			.box-shadow(0px 0px 51px 0px rgba(7, 3, 20, 0.35));

			.header {
				//margin-bottom: 15px;
			}

			.main {
				flex: 1 1 auto;
				display: flex;
				flex-direction: column;
				//min-height: 100%;
				.scrollbar {
					flex: 1 1 auto;
					display: flex;
					flex-direction: column;
					> div {
						//height: 100%;
						flex: 1 1 auto;
						display: flex;
						flex-direction: column;
						.body {
							flex: 1 0 auto;
							display: flex;
							flex-direction: column;
							align-items: stretch;
							justify-content: space-between;
							width: 100%;
							height: 100%;
							padding: 0 40px;
							overflow-x: hidden;
						}
						.body.withoutPadding {
							padding: 0;
						}
					}
				}
			}

			.footer {
				padding: 20px 40px 0;
			}
			//.header,
			.footer {
				position: relative;
			}
		}

		.modalPadding {
			padding: 30px 0 20px;
		}
		.fullPage {
			position: fixed;
			top: 0;
			left: 0;
			border-radius: 0;
			max-width: 100% !important;
			min-height: 100% !important;
		}
	}
	.modalContainer {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding: 20px;
	}
	.back,
	.close {
		position: absolute;
		top: 16px;
		z-index: 2000;
	}
	.back {
		left: 16px;
	}
	.close {
		right: 16px;
	}
}

.react-international-phone-country-selector {
	position: relative;
}
.react-international-phone-country-selector-button {
	display: flex;
	height: var(--react-international-phone-height, 38px);
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: none;
	border-radius: 8px 0 0 8px;
	border-right: 1px solid
		var(--grey-shades-with-blue-200);
	margin: 0;
	appearance: button;
	-webkit-appearance: button;
	background-color: var(
		--react-international-phone-country-selector-background-color,
		var(--react-international-phone-background-color, white)
	);
	cursor: pointer;
	text-transform: none;
	user-select: none;
}
.react-international-phone-country-selector-button:hover {
	background-color: var(
		--react-international-phone-country-selector-background-color-hover,
		whitesmoke
	);
}
.react-international-phone-country-selector-button--hide-dropdown {
	cursor: auto;
}
.react-international-phone-country-selector-button--hide-dropdown:hover {
	background-color: transparent;
}
.react-international-phone-country-selector-button__button-content {
	display: flex;
	align-items: center;
	justify-content: center;
}
.react-international-phone-country-selector-button__flag-emoji {
	margin: 0 4px 0 8px;
}
.react-international-phone-country-selector-button__flag-emoji--disabled {
	opacity: 0.75;
}
.react-international-phone-country-selector-button__dropdown-arrow {
	margin-right: 4px;
	transition: all 0.1s ease-out;
}
.react-international-phone-country-selector-button__dropdown-arrow--active {
	transform: rotateX(180deg);
}
.react-international-phone-country-selector-button__dropdown-arrow--disabled {
	border-top-color: var(--react-international-phone-disabled-country-selector-arrow-color, #999);
}
.react-international-phone-country-selector-button--disabled {
	background-color: var(
		--react-international-phone-disabled-country-selector-background-color,
		var(--react-international-phone-disabled-background-color, whitesmoke)
	);
	cursor: auto;
}
.react-international-phone-country-selector-button--disabled:hover {
	background-color: var(
		--react-international-phone-disabled-country-selector-background-color,
		var(--react-international-phone-disabled-background-color, whitesmoke)
	);
}
.react-international-phone-flag-emoji {
	width: var(--react-international-phone-flag-width, 18px);
	height: var(--react-international-phone-flag-height, 18px);
	box-sizing: border-box;
}
.react-international-phone-country-selector-dropdown {
	position: absolute;
	z-index: 1;
	top: var(--react-international-phone-dropdown-left, 42px);
	left: var(--react-international-phone-dropdown-left, -1px);
	display: flex;
	width: 240px;
	max-height: 200px;
	flex-direction: column;
	padding: 2px 4px 4px 4px;
	margin: 0;
	background-color: var(
		--react-international-phone-dropdown-item-background-color,
		var(--react-international-phone-background-color, white)
	);
	border: 1px solid var(--grey-shades-with-blue-100, gainsboro);
	box-shadow: var(--shadow100, 2px 2px 16px rgba(0, 0, 0, 0.25));
	color: var(
		--react-international-phone-dropdown-item-text-color,
		var(--react-international-phone-text-color, #222)
	);
	list-style: none;

	border-radius: 12px;

	/* disable scrollbars on majority of browsers https://stackoverflow.com/a/55144256 */
	overflow-y: scroll;
	overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* disable scrollbars on majority of browsers https://stackoverflow.com/a/55144256 */
.react-international-phone-country-selector-dropdown::-webkit-scrollbar { 
    width: 0 !important;
}

.react-international-phone-country-selector-dropdown:focus-visible { 
    outline: none;
}

.react-international-phone-country-selector-dropdown__list-item {
	display: flex;
	min-height: var(--react-international-phone-dropdown-item-height, 28px);
	box-sizing: border-box;
	align-items: center;
	padding: 2px 8px;
	border-radius: 8px;
	padding: 8px 0;
	margin-top: 2px;
}
.react-international-phone-country-selector-dropdown__list-item-flag-emoji {
	margin: 0 12px;
}
.react-international-phone-country-selector-dropdown__list-item-country-name {
	margin-right: 8px;
}
.react-international-phone-country-selector-dropdown__list-item-dial-code {
	display: none;
}
.react-international-phone-country-selector-dropdown__list-item:hover {
	background-color: var(--grey-shades-with-blue-100, whitesmoke);
	cursor: pointer;
}
.react-international-phone-country-selector-dropdown__list-item--selected,
.react-international-phone-country-selector-dropdown__list-item--focused {
	background-color: var(
		--primary-blueberry-150,
		whitesmoke
	);
	color: var(
		--react-international-phone-selected-dropdown-item-text-color,
		var(--react-international-phone-text-color, #222)
	);
}
.react-international-phone-country-selector-dropdown__list-item--selected
	.react-international-phone-country-selector-dropdown__list-item-dial-code,
.react-international-phone-country-selector-dropdown__list-item--focused
	.react-international-phone-country-selector-dropdown__list-item-dial-code {
	color: var(
		--react-international-phone-selected-dropdown-item-dial-code-color,
		var(--react-international-phone-dropdown-item-dial-code-color, gray)
	);
}

.react-international-phone-dial-code-preview {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 8px;
	border: 1px solid
		var(
			--react-international-phone-dial-code-preview-border-color,
			var(--react-international-phone-border-color, gainsboro)
		);
	margin-right: -1px;
	background-color: var(
		--react-international-phone-dial-code-preview-background-color,
		var(--react-international-phone-background-color, white)
	);
	color: var(
		--react-international-phone-dial-code-preview-text-color,
		var(--react-international-phone-text-color, #222)
	);
	font-size: var(
		--react-international-phone-dial-code-preview-font-size,
		var(--react-international-phone-font-size, 13px)
	);
}
.react-international-phone-dial-code-preview--disabled {
	background-color: var(
		--react-international-phone-dial-code-preview-disabled-background-color,
		var(--react-international-phone-disabled-background-color, whitesmoke)
	);
	color: var(
		--react-international-phone-dial-code-preview-disabled-text-color,
		var(--react-international-phone-disabled-text-color, #666)
	);
}
.react-international-phone-input-container {
	display: flex;
}
.react-international-phone-input-container .react-international-phone-country-selector-button {
	border-radius: var(--react-international-phone-border-radius, 4px);
	margin-right: -1px;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
.react-international-phone-input-container .react-international-phone-input {
	overflow: visible;
	height: var(--react-international-phone-height, 38px);
	box-sizing: border-box;
	padding: 0 8px;
	border: 1px solid var(--react-international-phone-border-color, gainsboro);
	border-radius: var(--react-international-phone-border-radius, 4px);
	margin: 0;
	background-color: var(--react-international-phone-background-color, white);
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	color: var(--react-international-phone-text-color, #222);
	font-family: inherit;
	font-size: var(--react-international-phone-font-size, 13px);
}
.react-international-phone-input-container .react-international-phone-input:focus {
	outline: none;
}
.react-international-phone-input-container .react-international-phone-input--disabled {
	background-color: var(--react-international-phone-disabled-background-color, whitesmoke);
	color: var(--react-international-phone-disabled-text-color, #666);
}

@import (reference) '~vars.less';
.keyframes(spinner, {0% {.rotate(0) ;} 100% {.rotate(359deg) ;}});
.component,
.children {
	display: inline-block;
}
.component.block,
.component.block .children {
	display: block;
}
.component.flex,
.component.flex .children {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	flex: 1 0 auto;
	//height: 0;
	//width: 100%;
	//height: 100%;
}
.component.absolute {
	position: absolute;
	top: 0;
	left: 0;
}
//.component.absolute .children {
//	position: absolute;
//	top: 0;
//	left: 0;
//}
.component {
	//overflow: hidden;
	position: relative;
}
.children {
	.transition(opacity, 500ms);
}
.container,
.spinner,
.spin {
	position: absolute;
}
.container,
.spin {
	top: 0;
	left: 0;
}
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 500;
	visibility: hidden;
	.opacity(100);
	.transitionMulti(opacity 500ms, visibility 500ms);
}
.component.visible {
	.container {
		visibility: visible;
		.opacity(100);
	}
	.children {
		.transition(opacity, 0ms);
		.opacity(30);
	}
	.hiddenChildren {
		.opacity(0);
	}
}
.spinner {
	left: 50%;
	top: 50%;
	width: 51px;
	height: 51px;
	.translate(-50%, -50%);
}
.spin {
	width: 100%;
	height: 100%;
	border: 6px solid transparent;
	border-color: @brand_500 transparent transparent transparent;
	.animation(spinner 1s cubic-bezier(0.19, 0.58, 0.82, 0.46) infinite);
	.radius(50%);
}
.spin:nth-child(2) {
	animation-delay: -0.08s;
}
.spin:nth-child(3) {
	animation-delay: -0.11s;
}
.container.white .spinner .spin {
	border-color: @white transparent transparent transparent;
}
.container.analytics .spinner .spin {
	border-color: @analytics transparent transparent transparent;
}
.container.drive .spinner .spin {
	border-color: @drive transparent transparent transparent;
}
.container.sheets .spinner .spin {
	border-color: @sheets transparent transparent transparent;
}
.container.dropbox .spinner .spin {
	border-color: @dropbox transparent transparent transparent;
}
.container.onedrive .spinner .spin {
	border-color: @onedrive transparent transparent transparent;
}
.container.warning_red_500 .spinner .spin {
	border-color: @warning_red_500 transparent transparent transparent;
}
/** FULLPAGE **/
.component.fullpage {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	// z-index: 9999; // commented out because of clash with Header info bubbles
}
/** INLINE **/
.component.inline {
	background-color: @grey_100;
	visibility: hidden;
	.opacity(0);
	.transitionMulti(visibility 150ms, opacity 150ms);
}
.component.inline.visible {
	visibility: visible;
	.opacity(100);
}
/** BACKGROUND **/
.component.background {
	background-color: @grey_100;
}

.dark {
	.spin {
		border-color: @black transparent transparent transparent;
	}
}

.light {
	.spin {
		border-color: @white transparent transparent transparent;
	}
}

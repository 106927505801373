.container {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ellipsis {
	overflow: hidden;
	word-break: break-word;
	:global(.tooltip-children) {
		overflow-wrap: anywhere;
	}
}

.tooltip {
	word-break: break-word;
}
@import (reference) '~vars.less';

.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: @white;
	background-repeat: no-repeat;
	background-position: 16px 20px;
	opacity: 0;
	z-index: 10000;
	visibility: hidden;
}
.visible {
	opacity: 1;
	visibility: hidden;
}
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	.image {
		background-size: cover;
		background-repeat: no-repeat;
		width: 160px;
		height: 160px;
	}
	.text {
		transform: translateY(-20px);
	}
}

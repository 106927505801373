.container {
	position: relative;
	//overflow: hidden;
	flex: 1 0 auto;
	display: flex;
	height: inherit;
	min-height: inherit;
	max-height: inherit;
	.slide {
		position: relative;
		flex: 1 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: 100%;
		max-height: 100%;
	}
}

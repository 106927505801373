@import (reference) '~vars.less';

.modalContent {
	:global(.content) {
		display: flex;
		height: 100%;
	}

	:global(.styledBody) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	:global(.styledGradient) {
		display: none !important;
	}
}

.learnMoreIcon {
	&:active {
		i {
			transform: translateY(0) !important;
		}
	}

	&:hover {
		i {
			color: @grey_shades_with_blue_500 !important;
		}
	}

	i {
		transition: color 200ms ease-in-out !important;
		color: @grey_shades_with_blue_300 !important;
	}

	&.active {
		i {
			color: @secondary_600 !important;
		}
		&:hover {
			:global(.background) {
				background-color: @secondary_300;
				opacity: 1;
			}
		}
	}
}

.noBg {
	:global(.background) {
		display: none;
	}
}

.learnMoreText {
	border: none !important;
}

.iframe {
	opacity: 1;
	visibility: visible;
	transition: all 300ms ease-in-out;

	&.hidden {
		opacity: 0;
		visibility: hidden;
	}
}

.tooltipContent {
	height: 400px;
	overflow: hidden;
}

.bubble {
	display: flex;
}
.wrapper {
	display: flex;
	align-items: center;
}

.saved {
	display: flex;
	align-items: center;
}

.icon {
	margin-right: 4px;
}
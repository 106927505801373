@import (reference) '~vars.less';

.notifications {
	z-index: 9999999999;
	position: fixed;
	right: 0;
	top: 0px;
	width: 100%;
	overflow: hidden;
	.transition(top, 200ms);
}

:global(.modal-open) .notifications {
	top: 0;
}

.notification {
	position: relative;
	width: 100%;
	height: 40px;
	cursor: pointer;
	overflow: hidden;
	text-align: center;
}

.content {
	margin: auto;
	font-weight: 500;
	font-size: 13px;
	line-height: 23px;
	color: @white;
	padding-left: 48px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);

	> * {
		display: inline-block;
	}
}

.close {
	color: @white;
	font-size: 10px;
	display: inline-block;
	margin-left: 48px;
}

.default {
	background: @grey_400;
}

.success {
	background: @success_green_500;
}

.warning {
	background: @attention_orange_500;
}

.danger {
	background: @warning_red_500;
}

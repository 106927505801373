@import (reference) '~vars.less';

.notifications {
	z-index: 2147483004 !important;
	position: fixed;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
}

@media (min-width: 784px) {
	.notifications {
		top: 0;
		bottom: unset;
	}
}

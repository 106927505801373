@import (reference) '~vars.less';

.loader {
	z-index: 0;
}

.headline {
	margin-bottom: 8px;
}

.subtitle {
	color: #5f6368;
	margin-bottom: 32px;
}

.flex {
	margin-bottom: 24px;
}

.sidebarContent {
	background: #f8f8f8;
	width: 288px;
	border-radius: 20px;

	.argumentSection {
		padding: 32px 32px 24px 32px;
		color: #5f6368;
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		border-bottom: 1px solid #e3e5e9;

		.title {
			margin-bottom: 16px;
		}

		[class*='Typo'] {
			display: flex;
			margin-bottom: 8px;

			.bullet {
				margin-right: 10px;
			}
		}
	}

	.customerSuccess {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #e3e5e9;

		.description {
			margin-left: 16px;
		}

		.faces {
			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
				background-image: url('assets/avatar.png');
			}
			background-image: url('assets/avatar.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			width: 40px;
			height: 40px;
		}
	}

	.footer {
		display: flex;
		justify-content: center;
		padding: 18px;
	}
}

.row {
	justify-content: space-between;
}

@import (reference) '~vars.less';

.content {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 32px 0;
}

.modalContent {
	:global(.styledBody) {
		padding: 0 !important;
	}
}

.panelWrapper {
	display: flex;
	flex-direction: column;

	.title {
		text-align: center;
		margin-bottom: 40px;

		:global(.responsesNumber) {
			color: @primary_500;
		}
	}

	.needMore {
		margin-top: 24px;
		white-space: pre-wrap;
		text-align: center;

		a {
			color: @primary_500;
		}
	}

	&.left {
		.title,
		.needMore {
			text-align: left;
		}
	}
}

.left {
	min-width: 480px;
	margin-right: 56px;

	.monthly {
		margin-bottom: 16px;
	}

	.subscription {
		margin-bottom: 24px;
	}

	.subtitle {
		margin-bottom: 24px;
	}

	//.radio :global(.label) {
	//	display: inline-block;
	//
	//	> div {
	//		display: inline-block;
	//	}
	//}
}

.right {
	display: flex;
	flex-direction: column;
	position: relative;
	border: 2px @grey_new_300 solid;
	border-radius: 24px;
	width: 400px;
	box-sizing: border-box;
	padding: 40px 40px 24px;

	.row {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.title {
		margin-bottom: 16px;
	}

	.separator {
		height: 2px;
		width: 100%;
		background-color: @grey_shades_with_blue_100;
		margin: 12px 0;
	}

	.vat {
		color: #7d8797;
		align-self: flex-end;
		margin-bottom: 32px;
	}

	.upgrade {
		margin-bottom: 16px;
	}

	.poweredBy {
		color: #7d8797;
		display: inline-flex;
		white-space: pre-wrap;
		align-items: center;
		width: 100%;
		justify-content: center;
		margin-top: 24px;
		z-index: 2;
	}

	.logo {
		display: flex;
		z-index: 2;

		> div {
			display: flex;
		}
	}

	.loader {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		background-color: #ffffff;
		z-index: 1;
		border-radius: 24px;
		opacity: 0;
		visibility: hidden;
		transition: opacity 200ms ease-in-out;
	}

	.visible {
		visibility: visible;
		opacity: 1;
	}

	.list {
		list-style-type: disc;
		padding-left: 18px;
		li:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	.payment {
		a {
			color: @primary_500;
		}
	}
}

.btm24Margin {
	margin-bottom: 24px;
}

.btm16Margin {
	margin-bottom: 16px;
}

.btm4Margin {
	margin-bottom: 4px;
}

.right16margin {
	margin-right: 16px;
}

.btm32Margin {
	margin-bottom: 32px;
}

.emailInput {
	margin-top: 24px;
	width: 100%;
}

.customFooter {
	padding: 48px 0;
}

.link {
	cursor: pointer;
	a {
		pointer-events: none;
	}
}

.with8BtmMargin {
	margin-bottom: 8px;
}

.flex {
	display: flex;
	white-space: pre-wrap;
	i {
		cursor: pointer;
	}
}

.flexEnd {
	display: flex;
	justify-content: flex-end;
}

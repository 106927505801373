.wrapper {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 9002;
	transition: all 200ms ease-out;
}

.label {
	position: relative;
	z-index: 9003;
	padding-top: 16px;
}

.bg {
	position: absolute;
	background: #FFFFFF;
	opacity: 0.9;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.children {
	z-index: 1;
}

@import (reference) '~vars.less';

.close {
	width: 26px;
	height: 26px;
	display: inline-block;
	border-radius: 999px;
	position: relative;
	cursor: pointer;

	&.grey {
		color: @space_blue_300;
	}
	&.default {
		background-color: #efeefd;
		color: @brand_500;
	}
	&.white {
		background-color: @white;
		color: @space_blue_600;
	}
	&.gray {
		background-color: transparent;
		color: @space_blue_300;
	}
	&.transparent {
		color: @white;
	}
	&.red {
		background-color: @warning_red_100;
		color: @warning_red_500;
	}
	&.primary {
		background-color: @white;
		color: @brand_500;
	}
	&.designer {
		background-color: @space_blue_300;
		color: @brand_600;
	}
	.icon {
		display: block;
		text-align: center;
		font-size: 9px;
		padding-top: 14px;
		line-height: 0;
		position: relative;
	}
}

:global(.no-touch) {
	.close {
		.transitionMulti(background-color 150ms ease-in-out, color 150ms ease-in-out);
	}
	.close.grey:hover {
		color: @space_blue_600;
	}
	.close.default:hover {
		background-color: @brand_500;
		color: @white;
	}
	.close.red:hover {
		background-color: @warning_red_300;
		color: @white;
	}
	.close.primary:hover {
		background-color: @brand_500;
		color: @white;
	}
	.close.designer:hover {
		background-color: @brand_500;
	}
}

@import (reference) '~vars.less';

:global(.window) {
	&.dialog {
		max-width: 1080px;
		max-height: 720px;
		width: calc(100dvw - 48px);
		height: calc(100dvh - 48px);
	}
}

.modalContent {
	:global(.styledBody) {
		padding: 0 !important;

		:global(.content) {
			height: 100%;
		}
	}
}

.close {
	position: absolute;
	top: 32px;
	right: 32px;
	z-index: 1000;
	height: 46px;
	width: 46px;
}
